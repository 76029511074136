// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import tw, { css, styled } from 'twin.macro'
import filterLocale from '../components/utils/filter-locale'
/** Maytronics Logo - SVG */
import Maytronics from './svg/maytronics'
/** Social Icons - SVG */
import Twitter from './svg/twitter'
import Github from './svg/github'
import Instagram from './svg/instagram'
import Facebook from './svg/facebook'
import YouTube from './svg/youtube'
import ClimateChange from './svg/spasa-climate-care-certified'
import localeCheck from './utils/locale-check'
/**
 * Social icons switch case function
 * @param {Object} props - The SVG props object
 * @param {string} props.size - The SVG size
 * @param {string} props.color - The SVG color
 * @param {string} props.icon - The SVG icon
 */
const SocialIcon = ({ size, color, icon }) => {
	switch (icon) {
		case 'twitter':
			return <Twitter width={size} height={size} color={color} />
		case 'github':
			return <Github width={size} height={size} color={color} />
		case 'instagram':
			return <Instagram width={size} height={size} color={color} />
		case 'facebook':
			return <Facebook width={size} height={size} color={color} />
		case 'youtube':
			return <YouTube width={size} height={size} color={color} />
		default:
			return null
	}
}
SocialIcon.propTypes = {
	size: PropTypes.string,
	color: PropTypes.string,
	icon: PropTypes.string,
}
/** Styles with Emotion & Tailwindcss */
const Footer = tw.footer`max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8`
const SiteMap = tw.div`xl:grid xl:grid-cols-3 xl:gap-8`
const Nav = tw.nav`mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2`
const Menu = tw.ul`md:grid md:grid-cols-2 md:gap-8`
const MenuItem = tw.li`mt-12 md:mt-0`
const SubMenu = tw.ul`mt-4`
const SubMenuItem = styled.li`
	a {
		${tw`text-base leading-6 text-gray-500 hover:text-gray-400`}
	}
`
const Brand = tw.div`xl:col-span-1`
const SocialLink = tw.a`text-gray-400 hover:text-gray-500`
const BotBar = tw.div`mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between`
const Enviro = styled.div`
	${tw`flex md:order-2 items-center text-sm font-normal`}
	a {
		${tw`text-gray-500 hover:text-gray-300`}
	}
`
const Legal = tw.p`mt-8 text-sm text-gray-500 text-base leading-6 md:mt-0 md:order-1`

/**
 * The footer for all pages, contains footer & social menu
 * @param {Object} props - Props object
 * @param {string} props.locale [locale=en-AU] - i18n locale code
 */
const PageFooter = ({ locale }) => {
	const { footer, social } = useStaticQuery(graphql`
		query getFooterMenus {
			footer: allContentfulNavMenu(
				filter: { contentful_id: { eq: "7E54HQXJEtpk7W310Ikdmw" } }
			) {
				edges {
					node {
						node_locale
						ariaLabel
						navId
						menuItems {
							... on ContentfulNavDropDown {
								ariaLabel
								dropDownItems {
									... on ContentfulNavItem {
										title
										url
									}
								}
							}
						}
					}
				}
			}
			social: allContentfulNavMenu(
				filter: { contentful_id: { eq: "39Cox3N8UQi2xJYLYYPh0E" } }
			) {
				edges {
					node {
						node_locale
						ariaLabel
						navId
						menuItems {
							... on ContentfulNavItem {
								title
								url
								icon
							}
						}
					}
				}
			}
		}
	`)
	/** Filter Array by Locale */
	const socialByLocale = filterLocale(social.edges, locale)
	const footerByLocale = filterLocale(footer.edges, locale)
	/** Destruct Filtered Array to Node */
	const socialMenu = socialByLocale[0].node
	const footerMenu = footerByLocale[0].node
	/** Restructure menu items into 2 columns */
	const col1 = [footerMenu.menuItems[0], footerMenu.menuItems[1]]
	const col2 = [footerMenu.menuItems[2], footerMenu.menuItems[3]]
	const row = [col1, col2]

	const defaultLocale = 'en-AU'
	const checkedLocale = localeCheck(locale, defaultLocale)
	return (
		<div css={tw`bg-gray-800 z-20`}>
			<Footer id="footer">
				<SiteMap>
					<Brand>
						<Maytronics color={'#fff'} />
						<p css={tw`mt-6 text-gray-500 text-base leading-6`}>
							Global Leaders in Robotic Pool Cleaners!
						</p>
						<div css={tw`mt-8 flex`}>
							{socialMenu.menuItems.map((n, i) => (
								<SocialLink
									key={i}
									href={n.url}
									target="_blank"
									rel="noopener noreferrer"
									css={[i >= 1 && tw`ml-6`]}
								>
									<span css={[tw`sr-only`]}>{n.title}</span>
									<SocialIcon size="24" color="currentColor" icon={n.icon} />
								</SocialLink>
							))}
						</div>
					</Brand>
					<Nav
						id={footerMenu.navId}
						role="navigation"
						aria-label={footerMenu.ariaLabel}
					>
						{row.map((col, colIndex) => (
							<Menu role="menu" aria-hidden="false" key={colIndex}>
								{col.map((item, i) => (
									<MenuItem
										key={i}
										role="menuitem"
										css={colIndex === 0 && item === 0 && tw`mt-0`}
									>
										<h4
											css={tw`text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase`}
										>
											{item.ariaLabel}
										</h4>
										{item.dropDownItems && (
											<SubMenu
												role="menu"
												aria-hidden="false"
												aria-label="submenu"
											>
												{item.dropDownItems.map((d, i) => (
													<SubMenuItem key={i} role="menuitem">
														<Link
															to={`${checkedLocale}/${d.url}`}
															tabIndex={-1}
														>
															{d.title}
														</Link>
													</SubMenuItem>
												))}
											</SubMenu>
										)}
									</MenuItem>
								))}
							</Menu>
						))}
					</Nav>
				</SiteMap>
				<BotBar>
					<Enviro>
						<Link to={`${checkedLocale}/climate-care-certified/`}>
							<ClimateChange size="120" color="currentColor" />
						</Link>
						<a
							href="https://sustainability.maytronics.com/"
							target="_blank"
							rel="noopener noreferrer"
							css={tw`ml-8`}
						>
							ISO 14001:2015
						</a>
					</Enviro>
					<Legal>
						&copy; {new Date().getFullYear()} Maytronics Australia. All rights
						reserved.
					</Legal>
				</BotBar>
			</Footer>
		</div>
	)
}
PageFooter.propTypes = {
	locale: PropTypes.string,
}
PageFooter.defaultProps = {
	locale: 'en-AU',
}

export default PageFooter
